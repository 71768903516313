<template>
 <div>
  <b-row>
    <b-col md="1">
    </b-col>
    <b-col md="10">
      <b-card md="1" bg-variant="none"> {{description}}</b-card>
    </b-col>
    <b-col md="1">
    </b-col>
  </b-row>
  <br>
  <b-card bg-variant="light">
    <div v-if="showListingSelection">
      <product-listing-select @selectedItems="setListingId" />
    </div>
    <div v-if="showCollectionSelection">
      <collection-select @selectedItems="setCollectionId" />
    </div>

    <ValidationObserver ref="observer">
    <b-form slot-scope="{ validate }" @submit.prevent="validate().then(callApi)">
    <b-form-group
      label="Stage"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="btn-radios-2"
        v-model="selected"
        :options="options"
        :aria-describedby="ariaDescribedby"
        button-variant="outline-primary"
        size="sm"
        name="radio-btn-outline"
        buttons
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group 
      label-cols-lg="3"
      label="Product Item"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >

      <b-form-group
        label="Description:"
        label-for="nested-description"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-description"
          v-model="attr_copy.description"
        ></b-form-input>
      </b-form-group>

     <b-form-group
        label="Serial / Copy #:"
        label-for="nested-serial-number"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-serial-number" type="number"
          v-model="attr_copy['serial-number']"
        ></b-form-input>
      </b-form-group>

     <b-form-group
        label="Purchased Price :"
        label-for="nested-purchased-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_estimate'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency id="nested-purchased-price" v-model="attr_copy['purchased-price-cents']">
          </b-form-input-currency>
        </b-input-group>
      </b-form-group>
    
     <b-form-group
        label="Estimated Min Price :"
        label-for="nested-estimated-min-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_estimate'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-estimated-min-price"
            v-model="attr_copy['estimated-min-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>

      <b-form-group
        label="Estimated Max Price :"
        label-for="nested-estimated-max-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_estimate'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-estimated-max-price"
            v-model="attr_copy['estimated-max-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>

     <b-form-group
        label="Authenticated Min Price :"
        label-for="nested-authenticated-min-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_authorize'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-authenticated-min-price"
            v-model="attr_copy['authenticated-min-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>

     <b-form-group
        label="Authenticated Max Price :"
        label-for="nested-authenticated-max-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_authorize'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-authenticated-max-price"
            v-model="attr_copy['authenticated-max-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>

     <b-form-group
        label="Asking Price :"
        label-for="nested-asking-price"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-asking-price"
            v-model="attr_copy['asking-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>
     <b-form-group
        label="Sold Price :"
        label-for="nested-sold-price"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_sell'"
      >
        <b-input-group size="sm" prepend="$">
          <b-form-input-currency
            id="nested-sold-price"
            v-model="attr_copy['sold-price-cents']"
          ></b-form-input-currency>
        </b-input-group>
      </b-form-group>

     <b-form-group
        label="Estimated Quality :"
        label-for="nested-estimated-quality"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_estimate'"
      >
        <b-input-group size="sm" prepend="%" :append="`${attr_copy['estimated-quality']/100}`">
          <b-form-input
            id="nested-estimated-quality"
            v-model="attr_copy['estimated-quality']"
            type="range" min="0" max="10000"
          ></b-form-input>
        </b-input-group>
     </b-form-group>

     <b-form-group
        label="Authenticated Quality :"
        label-for="nested-authenticated-quality"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_authorize'"
      >
        <b-input-group size="sm" prepend="%" :append="`${attr_copy['authenticated-quality']/100}`">
          <b-form-input
            id="nested-authenticated-quality"
            v-model="attr_copy['authenticated-quality']"
            type="range" min="0" max="10000"
          ></b-form-input>
        </b-input-group>
     </b-form-group>

     <b-form-group
        label="Collected At:"
        label-for="nested-collected_at"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_sell'"
      >
      <b-calendar id="nested-collected-at" v-model="attr_copy['collected-at']" locale="en-US"></b-calendar>
     </b-form-group>
      
     <b-form-group
        label="Prepared At:"
        label-for="nested-prepared-at"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_sell'"
      >
      <b-calendar id="nested-prepared-at" v-model="attr_copy['prepared-at']" locale="en-US"></b-calendar>
     </b-form-group>

     <b-form-group
        label="Delivered At:"
        label-for="nested-delivered-at"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_ship'"
      >
      <b-calendar id="nested-delivered-at" v-model="attr_copy['delivered-at']" locale="en-US"></b-calendar>
     </b-form-group>

     <b-form-group
        label="Received At:"
        label-for="nested-received-at"
        label-cols-sm="3"
        label-align-sm="right"
        v-show="selected === 'display_ship'"
      >
      <b-calendar id="nested-received-at" v-model="attr_copy['received-at']" locale="en-US"></b-calendar>
     </b-form-group>

    </b-form-group>
  </b-form>
  </ValidationObserver>
  <br>
    <b-row>
      <b-col>
        <b-button block variant="primary" :disabled="disableAdd" @click="callApi">Add</b-button>
      </b-col>
      <b-col>
        <b-button block variant="primary" @click="callCancel">Cancel</b-button>
      </b-col>
    </b-row>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import ProductListingSelect from './ProductListingSelect.vue'
import CollectionSelect from './CollectionSelect.vue'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'
import { ValidationObserver } from 'vee-validate'
import BFormInputCurrency from './BFormInputCurrency.vue'

export default {
  components: {
    ProductListingSelect,
    CollectionSelect,
    ValidationObserver,
    BFormInputCurrency
  },
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    },
  },
  data () {
    return {
      ams_type: 'product-items',
      ams_api_path: 'api/admin/v1/',
      attr_copy: {},
      attr_orig: {},
      description: undefined,
      selected: 'display_estimate',
      options: [
        { text: 'Estimate', value: 'display_estimate' },
        { text: 'Authorize', value: 'display_authorize' /*, disabled: true */ },
        { text: 'Sell', value: 'display_sell'},
        { text: 'Ship', value: 'display_ship' }
      ]
    }
  },
  computed: {
    showListingSelection() {
      return !(this.attr_copy['product-listing-id']?.length)
    },
    showCollectionSelection() {
      return !(this.attr_copy['collection-id']?.length)
    },
    disableAdd() {
      return !(
        this.attr_copy['product-listing-id']?.length
        && this.attr_copy['collection-id']?.length
        && this.attr_copy['serial-number']?.length
      )
    }
  },
  methods: {
    setListingId(items) {
      this.attr_copy['product-listing-id'] = items[0].id
    },
    setCollectionId(items) {
      this.attr_copy['collection-id'] = items[0].id
    },
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_')
    },
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.post (
        this.apiPath(), {
          ...attr_params
        }
      ).then(response => {
        if( response.data) {
         this.$store.dispatch('cached_products/add_product', response.data.data).then(() => {
          }).catch(() => {
          })
          this.$emit('takeAction')
          if(this.reroute) {
            this.$router.go(-1)
          }
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callCancel() {
      this.$emit('noAction')
      if(this.reroute) {
        this.$router.go(-1)
      }
    }
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_copy = {...this.ams_template.attributes}
    }
    else {
      const included_obj = amsSchema.find( 
        el =>  
        el.type === this.ams_type
      )
      this.attr_copy = {...included_obj.attributes}
    }
    this.description = this.ams_template?.description
  }
}
</script>